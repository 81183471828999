.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;

    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    &-bordered {
        border: 1px solid #dee2e6;

        th,
        td {
            border: 1px solid #dee2e6;
        }
    }
}

.panel {
    padding: 0.01em 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #eee;
}

.thumbPic {
    margin: 0;
    &:hover{
        img{
            transition: 0.3s;
            opacity: 0.8;
        }
    }
    img {
        line-height: 1;
        max-width: 100%;
    }
    &__title{
        padding-top: 10px;

    }
}


.breadcrumb {
    width: 100%;
    height: auto;
    background: #47588e;
    padding: 10px 0 10px 20px;

    a {
        color: #fff;
    }

    span {
        color: #fff;

        &:active {
            color: #ffa6a6;
        }
    }
}

.sidebar {
    height: 100%;
    width: 200px;
    background-color: #fff;
    position: fixed !important;
    z-index: 1;
    overflow: auto
}

.pages {
    margin: 20px auto;
    display: table;

    a,
    span {
        background: #ccc;
        color: #333;
        margin: 0 4px;
        display: block;
        float: left;
        padding: 6px 15px !important;
        border-radius: 4px;
        transition: 0.6s;

        &.current,
        &:hover {
            background: $color1;
            transition: 0.6s;
            color: #fff;
        }
    }

    span.pages {
        background: #ccc;
        color: #000;
    }
}

.container-full {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.block {
    display: block;
    width: 100%;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        padding: 8px 16px;
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom: none
        }
    }

    &-lineNone {
        li {
            border-bottom: none;
        }
    }

    &-unstyled {
        padding-left: 0;
        list-style: none;
    }
}

.border {
    border: 1px solid #ccc;
}

.btn,
.button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    background: #eee;
}

.btn:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.media {
    display: table;
    &>div{
        display: table-cell;
        vertical-align: top;
    }
    img {
        width: 100%;
    }

    &-body {
        padding-left: 20px;
    }
}

.iconMid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
        &:before {
            margin: 0;
        }
    }
}