body.LP{
    padding-top:90px;
    @media #{$r1024}{
        padding-top:65px;
    }
}
header{
    border-bottom:1px solid #eee;
    position: fixed;
    z-index: 10;
    width:100%;
    top:0;
    background-color: #fff;
    @extend %font1;
    @media #{$r1024}{
        background-color:#fff;
        height:65px;
    }
    .siteHeader {
        display: flex;
        justify-content: space-between;
        @media #{$r1024}{
            display:block;
        }
        &-bg{
            background:rgba(0,0,0,.8);
			width:100%;
			height:100%;
			content:'';
			display: none;
			position: fixed;
            top:0;
            left:0;
            z-index: 2;
            animation:opac 0.4s;
			&.active{
                display: block;
			}
        }
        &__section {
            display: flex;
            align-items: center;
            @media #{$r1024}{
                display: block;
                width:100%;
            }
        }
        &__item {
            padding: 36px 12px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            color: #333;
            font-weight: 600;
            @media #{$r1024}{
                padding:9px 12px;
                display: block;
                font-weight: 600;
                border-top:1px dotted #ccc;
            }
            &-menuHome{
                @media #{$r1024}{
                    display: none;
                }
            }
            &-logosb{
                display: none;
                @media #{$r1024}{
                    display: block;
                    text-align: center;
                }
                img{
                    max-width:90%;
                    margin:20px 0 0;
                }
            }
        }
        
        &__item+&__item {
            margin-left: 5px;
        }
        &Main{
            @media #{$r1024}{
                display: none;
                width:270px;
                background:#fff;
                position: fixed;
                height:100%;
                left:0;
                top:0;
                z-index: 3;
                overflow: auto;
                padding-bottom:20px;
            }
            &.active{
                display: block;
                animation:animateleft 0.4s;
            }
            .menu{
                display: flex;
                list-style: none;
                padding: 0;
                @media #{$r1024}{
                    display: block;
                }
                &Sub{
                    &:hover{
                        .menuSubBox{
                            display: block;
                            animation:opac 0.4s;
                            @media #{$r1024}{
                                display: none;
                            }
                        }
                        &>a{
                            color:#e3a261;
                        }
                    }
                    &Box{
                        display: none;
                        position: absolute;
                        width:80%;
                        right:16px;
                        top:95px;
                        background:#2f3d6b;
                        padding:30px 40px;
                        color:#fff;
                        @media #{$r1024}{
                            position: inherit;
                            width:100%;
                            padding:10px 15px;
                        }
                        ul{
                            list-style: none;
                            padding:0;
                            li{
                                border-bottom:1px dotted rgba(255,255,255,.2);
                                padding:10px 0;
                                font-size:13px;
                                &:last-child{
                                    border-bottom:none;
                                }
                            }
                        }
                        a{
                            color:#fff;
                            transition: 0.1s;
                            &:hover{
                                color:rgba(255,255,255,.7);
                            }
                        }
                    }
                    &Title{
                        text-transform: uppercase;
                        font-size:16px;
                        font-weight: 600;
                        position: relative;
                        padding-bottom:15px;
                        margin-bottom: 10px;
                        @media #{$r1024}{
                            margin-top:10px;
                        }
                        &:after,
                        &::before{
                            content:'';
                            display: block;
                            position: absolute;
                        }
                        &:before{
                            width:80px;
                            height:3px;
                            background:#fff;
                            left:0;
                            bottom:-1px;
                        }
                        &:after{
                            width:100px;
                            width:100%;
                            height:1px;
                            background:rgba(255,255,255,.2);
                            left:0;
                            bottom:0;
                        }
                    }
                }
            }
        }
        &Logo {
            height:90px;
            display: flex;
            align-items: center;
            @media #{$r1024}{
                text-align: center;
                width:180px;
                margin:0 auto;
                position: relative;
                display: block;
            }
            @media #{$r414}{
                width:140px;
            }
            img{
                @media #{$r1024}{
                    position: absolute;
                    top:10px;
                    left:0;
                    height:45px;
                }
                @media #{$r414}{
                    height:36px;
                    top:15px;
                }
            }
        }
        &Nav,
        &Call{
            position: absolute;
            top:13px;
            display: none;
            @media #{$r1024}{
                display: flex;
            }
        }
        &Nav{
            font-size:30px;
            width:40px;
            height:40px;
            left:5px;
            color:#00597d;
            padding-right:17px;
            border-right:1px solid #eee;
            @media #{$r1024}{
                left:-8px;
            }
            @media #{$r768}{
                left:10px;
            }
            @media #{$r414}{
                left:17px;
            }
        }
        &Call{
            font-size:20px;
            width:40px;
            height:40px;
            right:-15px;
            line-height: 16px;
            border-radius: 4px;
            background:#e43c3b ;
            color:#fff;
            @media #{$r768}{
                right:5px;
            }
            @media #{$r414}{
                right:12px;
            }
        }
        &__item.active {
            background-color: #eee;
        }
        &Regist,&Phone{
            border-radius: 4px;
            color:#fff;
            padding: 9px 15px;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            
            @media #{$r1024}{
                margin:0 5px;
                display: block;
                text-align: center;
                border-radius: 6px;
            }
        }
        &Regist{
            background:#37a688;
            margin-left:10px;
            @media #{$r1024}{
                margin-left:5px;
                margin-top:5px;
            }
        }
        &Phone{
            background:#dcaf47;
            margin-left:20px;
            @media #{$r1024}{
                margin-left:5px;
            }

        }
    }
    
    &.menufix{
        box-shadow: 0 0 4px rgba(0,0,0,.3);
        animation:animatetop 0.4s;
        @media #{$r1024}{
            animation:none;
        }
        .siteHeader{
            &__item{
                padding:26px 12px;
                @media #{$r1024}{
                    padding:9px 12px;
                }
            }
            &Logo{
                height:70px;
                img{
                    transition: 0.3s;
                    @media #{$r1024}{
                        height:45px;
                    }
                    @media #{$r414}{
                        height:36px;
                    }
                }
            }
            &Main{
                .menu{
                    &Sub{
                        &Box{
                            top:65px;
                        }
                    }
                }
            }
        }
    }
}







