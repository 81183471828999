@font-face {
    font-family: bebas;
    src: url(../fonts/UTM_Bebas.woff) format('woff')
}
.regft {
    display: none;
    @extend %font1;

    @media #{$r768} {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        height: 60px;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        bottom: 0;
        z-index: 2;

        a {
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin: 10px 0 10px 10px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            text-align: left;
            border-radius: 4px;
            font-size: 15px;
            font-weight: normal;
            text-transform: uppercase;
            padding-left: 20px;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:nth-child(1) {
                background: #156953;
                box-shadow: 0px 3px 0px 0px #124a3b;
            }

            &:nth-child(2) {
                background: #e23d3a;
                margin-right: 10px;
                box-shadow: 0px 2px 0px 0px #8f2725;

                @media #{$r375} {
                    font-size: 13px;
                    text-align: center;
                    padding-left: 0;

                    i {
                        display: none;
                    }
                }
            }
        }
    }
}
%font3{
    font-family: 'bebas', sans-serif;
}
.loaded {
	animation: opac 0.8s;
}

.temp {
	padding-top: 40px;
	padding-bottom: 40px;
	background-position: center 0;
	background-repeat: no-repeat;
	@media #{$r1024} {
		padding-top: 30px;
	}
	@media #{$r414} {
		padding-bottom:20px;
	}
	&.mbReg{
		padding:0;
	}

	&Title {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: 600;
		line-height: 38px;
		@extend %font1;
		margin-bottom: 40px;
		text-align: center;

		@media #{$r1024} {
			font-size: 22px;
		}

		@media #{$r414} {
			font-size: 17px;
			line-height: 30px;
			margin-bottom: 20px;
			br{
				display: none;
			}
		}
	
		&-white {
			color: #fff;
		}

		&-color {
			color: #004689;
		}
	}

	&Sub {
		text-align: center;
		font-size: 18px;
		text-transform: uppercase;
		@media #{$r414}{
			font-size:12px;
		}
		@media #{$r320}{
			font-size:10px;
		}
	}

	&Line {
		background-repeat: no-repeat;
		width: 258px;
		border-top: 1px solid #d8d8d8;
		position: relative;

		@media #{$r768} {
			margin: 20px auto 30px;
		}

		&::before {
			width: 100px;
			margin: 0;
			border-top: 3px solid #f69679;
			position: absolute;

			top: -2px;
			content: "";

			@media #{$r768} {
				left: 76px;
			}
		}

		&-left {
			margin: 10px 0 30px;
			border:none;
			&::before {
				width:70px;
				left: 0;
			}
		}

		&-center {
			margin: 20px auto 30px;

			&::before {
				left: 80px;
			}
		}
	}

	&2,
	&3,
	&4 {
		position: relative;

		&:after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
		}

		&.loaded:after {
			opacity: 0;
		}
	}
}

.regist {
	@extend %font1;
	font-size:14px;
	@media #{$r414}{
		padding-bottom: 10px;
	}
	&Note{
		font-style: italic;
		margin-bottom:5px;
		text-align: center;
		font-size:13px;
		&-white{
			color:#fff;
		}
	}
	&Button {
		background:linear-gradient(to right,#deb44a,#f6e096,#deb44a);
		color: #000;
		border-radius: 28px;
		text-transform: uppercase;
		padding:8px 10px 8px 15px;
		display: table;
		font-weight: 600;
		margin: 15px auto 0;
		@media #{$r768}{
			padding:8px 8px 8px 13px;
			font-size:12px;
		}

		@media #{$r414} {
			font-size: 13px;
			padding: 6px 14px;
			display: table;
			margin: 10px auto 0;
		}
		@media #{$r320}{
			padding:5px 2px 7px 8px;
			font-size:11px;
		}
	}

	a {
		&:after{
			display: none;
			width:0;
			overflow: hidden;
			transition: 0.3s;
			content: '\e895';
			display: inline-block;
			@extend %icon;
		}
		&:hover::after {
			width:15px;
			
		}
	}

	&Note {
		@media #{$r414} {
			font-size: 12px;
		}

		&-white {
			color: #fff;
		}

		&-color {
			color: #e7b57a;
		}
	}
}

.temp1 {
	background: url(../images/t1-bg.jpg) center 0 no-repeat;
	min-height: 780px;
	@media #{$r1440}{
		background-size:121%;
		min-height: 577px;
	}
	@media #{$r1366}{
		min-height:540px;
	}
	@media #{$r1280}{
		min-height:500px;
		background-size:127%;
	}
	@media #{$r1024}{
		min-height:410px;
		background-size:135%;
	}
	@media #{$r768}{
		background-size:165%;
		background-position: -210px 0;
	}
	@media #{$r414}{
		background:url(../images/t1-bg-m.jpg) center 0 no-repeat;
		background-size:100%;
		min-height: 768px;
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		justify-content: center;
		padding-bottom: 20px;
	}
	@media #{$r375}{
		min-height:310px;
	}
	@media #{$r360}{
		min-height:295px;
	}
	@media #{$r320}{
		min-height:260px;
		background-size:103%;
	}
	.registNote{
		@media #{$r414}{
			color:#fff;
		}
	}
	.countdown {
		background: url(../images/cd.png) no-repeat 0 0;
		background-size: 100%;
		width: 430px;
		margin: 0 auto;
		height: 115px;
		font-size: 65px;
		color: #fff;
		word-spacing: 50px;
		letter-spacing: 3px;
		padding: 0 0 0 5px;
		line-height: 64px;
		@extend %font3;
		@media #{$r1440} {
			width:350px;
			font-size:54px;
			word-spacing: 37px;
			line-height: 53px;
		}
		@media #{$r1366}{
			height:95px;
		}
		@media #{$r414}{
			background-image: url(../images/cd2.png);
		}
		@media #{$r375}{
			width:290px;
			font-size:40px;
			line-height: 44px;
			word-spacing: 34px;
			height:85px;
		}
		@media #{$r320}{
			height: 77px;
		}
		
	}
	&__box{
		width:370px;
		margin-top:390px;
		@media #{$r1440}{
			margin-top:350px;
			margin-left:65px;
		}
		@media #{$r1366}{
			margin-top:320px;
			margin-left:90px;
		}
		@media #{$r1024}{
			margin-left:38px;
			margin-top:280px;
		}
		@media #{$r768}{
			margin-left:-14px;
			margin-top:255px;
		}
		@media #{$r375}{
			margin-top:465px;
		}
		@media #{$r360}{
			margin-top:445px;
		}
		@media #{$r320}{
			margin-top:396px;
			margin-left:0;
		}
	}
	.regist{
		margin-top:50px;
		@media #{$r1440}{
			margin-top:0;
		}
	}
	
}

.temp2 {
	&.loaded{
		background-image:url(../images/t2-bg.jpg);
		@media #{$r414}{
			background-image:url(../images/t2-bg-m.jpg);
		}
	}
	@media #{$r1440}{
		background-size:110%;
	}
	@media #{$r1366}{
		background-size:120%;
	}
	@media #{$r1024}{
		background-size:140%;
	}
	@media #{$r768}{
		background-size:160%;
	}
	@media #{$r414}{
		background-size:100%;
	}

	.regist{
		margin-top:800px;
		@media #{$r1440}{
			margin-top:570px;
		}
		@media #{$r768}{
			margin-top:470px;
		}
		@media #{$r414}{
			margin-top:680px;
		}
		@media #{$r375}{
			margin-top:600px;
		}
		@media #{$r320}{
			margin-top:500px;
		}
	}

}
.temp3 {
	&.loaded{
		background-image:url(../images/t3-bg.jpg);
		@media #{$r414}{
			background-image:url(../images/t3-bg-m.jpg);
		}
	}
	@media #{$r1440}{
		background-size:110%;
	}
	@media #{$r1366}{
		background-size:120%;
	}
	@media #{$r1280}{
		background-size:128%;
	}
	@media #{$r1024}{
		background-size:155%;
	}
	@media #{$r768}{
		background-size:163%;
	}
	@media #{$r414}{
		background-size:100%;
	}
	.regist{
		margin-top:1110px;
		@media #{$r1440}{
			margin-top:900px;
		}
		@media #{$r768}{
			margin-top:700px;
		}
		@media #{$r414}{
			margin-top:10px;
		}
		
	}
	.bs{
		display: none;
		padding-top:120px;
		@media #{$r414}{
			display: block;
		}
		&__pic{
			width:230px;
			margin:0 auto;
		}
		&__name{
			text-align: center;
			margin:15px 0;
			span{
				display: block;
				text-transform: uppercase;
				font-weight: 600;
				color:#156d5c;
				font-size:16px;
			}
			i{
				font-size:12px;
				color:#000;
			}
		}
		ul{
			padding-left:20px;
			li{
				color:#666;
				text-align: justify;
			}
		}
		
	}
	.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
		background:#156d5c;
	}
	.owl-theme .owl-nav [class*=owl-]{
		padding:0 10px;
		background:#ccc;
		font-size:18px;
		color:#fff;
		margin-bottom:20px;
	}
}
.temp4 {
	&.loaded{
		background-image:url(../images/t4-bg.jpg);
		@media #{$r414}{
			background-image:url(../images/t4-bg-m.jpg);
		}
	}
	@media #{$r1440}{
		background-size:110%;
	}
	@media #{$r1366}{
		background-size:120%;
	}
	@media #{$r1280}{
		background-size:126%;
	}
	@media #{$r1024}{
		background-size:153%;
	}
	@media #{$r768}{
		background-size:170%;
	}
	@media #{$r414}{
		background-size:100%;
	}
	.regist{
		margin-top:660px;
		@media #{$r1440}{
			margin-top:516px;
		}
		@media #{$r768}{
			margin-top:407px;
		}
		@media #{$r414}{
			margin-top:1408px;
		}
		@media #{$r375}{
			margin-top:1268px;
		}
		@media #{$r360}{
			margin-top:1230px;
		}
		@media #{$r320}{
			margin-top:1080px;
		}
		
	}
}
.temp5 {
	min-height: 678px;
	&.loaded{
		background-image:url(../images/t5-bg.jpg);
		@media #{$r414}{
			background-image:url(../images/t5-bg-m.jpg);
		}
	}
	@media #{$r1440}{
		background-size:110%;
		min-height:555px;
	}
	@media #{$r1366}{
		background-size:120%;
		min-height:565px;
	}
	@media #{$r1280}{
		min-height:535px;
	}
	@media #{$r1024}{
		background-size:150%;
	}
	@media #{$r768}{
		background-size:160%;
		min-height: 435px;
	}
	@media #{$r414}{
		background-size:100%;
		min-height:784px;
	}
	@media #{$r375}{
		min-height: 700px;
	}
	@media #{$r360}{
		min-height:670px;
	}
	@media #{$r320}{
		min-height: 580px;
	}

}


.tempReg {
	&.loaded{
		background-image:url(../images/reg-bg.jpg);
		@media #{$r414}{
			background: linear-gradient(#156d5c,#63455e);
		}
	}
	padding:135px 0;
	@media #{$r1440}{
		background-size:110%;	
		padding:90px 0;
	}
	@media #{$r1366}{
		padding:80px 0;
	}
	@media #{$r1280}{
		padding:60px 0;
	}
	@media #{$r1024}{
		background-size:130%;
	}
	@media #{$r768}{
		padding:489px 0 15px;
		background-size:210%;
		background-position: -140px 0;
	}
	@media #{$r414}{
		padding:0;
		
	}
	.tempTitle{
		@media #{$r414}{
			margin-bottom:0;
		}
		img{
			@media #{$r1440}{
				max-width: 600px;
			}
			@media #{$r375}{
				max-width: 100%;
			}
		}
	}
	.box{
		flex-direction: row-reverse;
	}
	.box1 {
		right: -100px;
		position: relative;
		@media #{$r1440}{
			right:0;
		}
		@media #{$r1366} {
			right: -30px;
		}
		@media #{$r1024} {
			right: 30px;
		}
		@media #{$r768} {
			right: 0px;
		}
	}
	
	.form {
		margin: 10px auto 10px;
		width: 93%;
		color: #5b5555;
		text-align: center;
		@media #{$r1366}{
			width:79%;
			margin:10px auto 10px 83px;
		}
		@media #{$r768}{
			width:100%;
			margin:10px auto;
		}
		h3 {
			font-size: 28px;
			text-transform: uppercase;
			@media #{$r1366}{
				font-size:22px;
			}
			@media #{$r1024}{
				font-size:21px;
			}
			@media #{$r414}{
				margin:0;
			}
			@media #{$r320}{
				font-size:18px;
			}
			img {
				max-width: 100%;
				@media #{$r768} {
					width: auto;
				}
			}
		}
		.deal {
			padding-bottom: 5px;
			b {
				display: block;
				font-size: 15px;
				text-transform: uppercase;
			}
		}
		h4 {
			font-size: 13px;
			font-weight: normal;
			font-style: italic;
			margin: 0 0 30px;
			line-height: 18px;
			@media #{$r1024}{
				font-size:11px;
			}
			span {
				text-transform: uppercase;
			}
		}
		hr {
			border-top: 1px solid #eee;
			margin: 10px 0;
		}
		iframe {
			height: 50px;
			@media #{$r1024} {
				height: 44px;
			}
		}
		&>div {
			padding: 30px 65px;
			margin: 0;
			background: linear-gradient(to right,#156d5c,#63455e);
			color:#fff;
			@media #{$r1440} {
				padding: 30px 65px;
			}
			@media #{$r1024} {
				padding: 20px 38px;
			}
			@media #{$r768} {
				margin-right: 0;
			}
			@media #{$r414} {
				padding:20px;
				background:none;
			}
		}
		article {
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}
		.dkbt {
			padding: 10px 0 0;
			position: relative;
			.mxh {
				position: absolute;
				right: 50px;
				top: 15px;
				width: 80px;
				@media #{$r1024} {
					right: 30px;
				}
			}
		}
		.fct input,
		.fct textarea,
		.fct select {
			width: 100%;
			margin: 4px 0 7px;
			border: 1px solid #ddd;
			padding: 8px 12px;
			border-radius: 4px;
			@media #{$r1366}{
				margin: 4px 0 2px;
			}
		}
		.fct textarea {
			height: 102px;
			resize: none;
			font-family: arial;
			font-size: 14px;
			@media #{$r1024}{
				height:62px;
			}
		}
		.fct {
			input:focus::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				color: #000;
			}
			input:focus::-moz-placeholder {
				/* Firefox 19+ */
				color: #000;
			}
			input:focus:-ms-input-placeholder {
				/* IE 10+ */
				color: #000;
			}
			input:focus:-moz-placeholder {
				/* Firefox 18- */
				color: #000;
			}
		}
		.fbt {
			background:linear-gradient(to right,#deb44a,#f6e096,#deb44a);
			border: none;
			color: #000;
			display: inline-block;
			cursor: pointer;
			margin: 0 auto;
			border-radius: 24px;
			font-size: 14px;
			padding:8px 20px;
			text-transform: uppercase;
			@extend %font1;
			@media #{$r1024} {
				font-size: 16px;
			}
			@media #{$r375} {
				font-size: 12px;
			}
		}
		
	}
	
  
   
}