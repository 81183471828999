@font-face {
	font-family: 'fontello';
	src: url(../fonts/fontello.woff2?39412834) format("woff2");
	font-weight: 400;
	font-style: normal
}

[class^="icon-"]:before, [class*=" icon-"]:before,[class^="flex-"]:before, [class*=" flex-"]:before,%icon {
	font-family: "fontello";
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-spin6:before {
	content: '\e800'
}

.icon-spin5:before {
	content: '\e801'
}

.icon-spin4:before {
	content: '\e802'
}

.icon-spin3:before {
	content: '\e803'
}

.icon-spin2:before {
	content: '\e804'
}

.icon-spin1:before {
	content: '\e805'
}

.icon-glass:before {
	content: '\e806'
}

.icon-music:before {
	content: '\e807'
}

.icon-search:before {
	content: '\e808'
}

.icon-mail:before {
	content: '\e809'
}

.icon-mail-alt:before {
	content: '\e80a'
}

.icon-heart:before {
	content: '\e80b'
}

.icon-heart-empty:before {
	content: '\e80c'
}

.icon-star:before {
	content: '\e80d'
}

.icon-star-empty:before {
	content: '\e80e'
}

.icon-star-half:before {
	content: '\e80f'
}

.icon-star-half-alt:before {
	content: '\e810'
}

.icon-user:before {
	content: '\e811'
}

.icon-user-plus:before {
	content: '\e812'
}

.icon-user-times:before {
	content: '\e813'
}

.icon-users:before {
	content: '\e814'
}

.icon-male:before {
	content: '\e815'
}

.icon-female:before {
	content: '\e816'
}

.icon-child:before {
	content: '\e817'
}

.icon-video:before {
	content: '\e818'
}

.icon-videocam:before {
	content: '\e819'
}

.icon-picture:before {
	content: '\e81a'
}

.icon-camera:before {
	content: '\e81b'
}

.icon-camera-alt:before {
	content: '\e81c'
}

.icon-th-large:before {
	content: '\e81d'
}

.icon-th:before {
	content: '\e81e'
}

.icon-th-list:before {
	content: '\e81f'
}

.icon-ok:before {
	content: '\e820'
}

.icon-ok-circled:before {
	content: '\e821'
}

.icon-ok-circled2:before {
	content: '\e822'
}

.icon-ok-squared:before {
	content: '\e823'
}

.icon-cancel:before {
	content: '\e824'
}

.icon-cancel-circled:before {
	content: '\e825'
}

.icon-cancel-circled2:before {
	content: '\e826'
}

.icon-plus:before {
	content: '\e827'
}

.icon-plus-squared:before {
	content: '\e828'
}

.icon-plus-squared-alt:before {
	content: '\e829'
}

.icon-plus-circled:before {
	content: '\e82a'
}

.icon-minus:before {
	content: '\e82b'
}

.icon-minus-circled:before {
	content: '\e82c'
}

.icon-minus-squared:before {
	content: '\e82d'
}

.icon-minus-squared-alt:before {
	content: '\e82e'
}

.icon-help:before {
	content: '\e82f'
}

.icon-info-circled:before {
	content: '\e830'
}

.icon-help-circled:before {
	content: '\e831'
}

.icon-info:before {
	content: '\e832'
}

.icon-home:before {
	content: '\e833'
}

.icon-link:before {
	content: '\e834'
}

.icon-unlink:before {
	content: '\e835'
}

.icon-link-ext:before {
	content: '\e836'
}

.icon-link-ext-alt:before {
	content: '\e837'
}

.icon-attach:before {
	content: '\e838'
}

.icon-lock:before {
	content: '\e839'
}

.icon-lock-open:before {
	content: '\e83a'
}

.icon-lock-open-alt:before {
	content: '\e83b'
}

.icon-pin:before {
	content: '\e83c'
}

.icon-eye:before {
	content: '\e83d'
}

.icon-eye-off:before {
	content: '\e83e'
}

.icon-tag:before {
	content: '\e83f'
}

.icon-tags:before {
	content: '\e840'
}

.icon-bookmark:before {
	content: '\e841'
}

.icon-bookmark-empty:before {
	content: '\e842'
}

.icon-flag:before {
	content: '\e843'
}

.icon-flag-empty:before {
	content: '\e844'
}

.icon-flag-checkered:before {
	content: '\e845'
}

.icon-thumbs-up:before {
	content: '\e846'
}

.icon-thumbs-down:before {
	content: '\e847'
}

.icon-thumbs-up-alt:before {
	content: '\e848'
}

.icon-thumbs-down-alt:before {
	content: '\e849'
}

.icon-download:before {
	content: '\e84a'
}

.icon-upload:before {
	content: '\e84b'
}

.icon-download-cloud:before {
	content: '\e84c'
}

.icon-upload-cloud:before {
	content: '\e84d'
}

.icon-reply:before {
	content: '\e84e'
}

.icon-reply-all:before {
	content: '\e84f'
}

.icon-quote-right:before {
	content: '\e850'
}

.icon-code:before {
	content: '\e851'
}

.icon-export:before {
	content: '\e852'
}

.icon-export-alt:before {
	content: '\e853'
}

.icon-share:before {
	content: '\e854'
}

.icon-pencil:before {
	content: '\e855'
}

.icon-pencil-squared:before {
	content: '\e856'
}

.icon-edit:before {
	content: '\e857'
}

.icon-print:before {
	content: '\e858'
}

.icon-retweet:before {
	content: '\e859'
}

.icon-comment:before {
	content: '\e85a'
}

.icon-chat:before {
	content: '\e85b'
}

.icon-comment-empty:before {
	content: '\e85c'
}

.icon-chat-empty:before {
	content: '\e85d'
}

.icon-bell:before {
	content: '\e85e'
}

.icon-bell-alt:before {
	content: '\e85f'
}

.icon-bell-off:before {
	content: '\e860'
}

.icon-bell-off-empty:before {
	content: '\e861'
}

.icon-quote-left:before {
	content: '\e862'
}

.icon-attention-alt:before {
	content: '\e863'
}

.icon-attention:before {
	content: '\e864'
}

.icon-attention-circled:before {
	content: '\e865'
}

.icon-location:before {
	content: '\e866'
}

.icon-direction:before {
	content: '\e867'
}

.icon-compass:before {
	content: '\e868'
}

.icon-trash:before {
	content: '\e869'
}

.icon-trash-empty:before {
	content: '\e86a'
}

.icon-doc:before {
	content: '\e86b'
}

.icon-docs:before {
	content: '\e86c'
}

.icon-doc-text:before {
	content: '\e86d'
}

.icon-doc-inv:before {
	content: '\e86e'
}

.icon-doc-text-inv:before {
	content: '\e86f'
}

.icon-folder:before {
	content: '\e870'
}

.icon-folder-open:before {
	content: '\e871'
}

.icon-rss:before {
	content: '\e872'
}

.icon-rss-squared:before {
	content: '\e873'
}

.icon-phone:before {
	content: '\e874'
}

.icon-phone-squared:before {
	content: '\e875'
}

.icon-menu:before {
	content: '\e876'
}

.icon-cog:before {
	content: '\e877'
}

.icon-cog-alt:before {
	content: '\e878'
}

.icon-wrench:before {
	content: '\e879'
}

.icon-sliders:before {
	content: '\e87a'
}

.icon-calendar:before {
	content: '\e87b'
}

.icon-calendar-empty:before {
	content: '\e87c'
}

.icon-login:before {
	content: '\e87d'
}

.icon-logout:before {
	content: '\e87e'
}

.icon-volume-off:before {
	content: '\e87f'
}

.icon-volume-down:before {
	content: '\e880'
}

.icon-volume-up:before {
	content: '\e881'
}

.icon-headphones:before {
	content: '\e882'
}

.icon-clock:before {
	content: '\e883'
}

.icon-down-dir:before {
	content: '\e884'
}

.icon-up-dir:before {
	content: '\e885'
}

.icon-left-dir:before {
	content: '\e886'
}

.icon-right-dir:before {
	content: '\e887'
}

.icon-down-open:before {
	content: '\e888'
}

.icon-left-open:before {
	content: '\e889'
}

.icon-right-open:before {
	content: '\e88a'
}

.icon-up-open:before {
	content: '\e88b'
}

.icon-angle-left:before {
	content: '\e88c'
}

.icon-angle-right:before {
	content: '\e88d'
}

.icon-angle-up:before {
	content: '\e88e'
}

.icon-angle-down:before {
	content: '\e88f'
}

.icon-angle-circled-left:before {
	content: '\e890'
}

.icon-angle-circled-right:before {
	content: '\e891'
}

.icon-angle-circled-up:before {
	content: '\e892'
}

.icon-angle-circled-down:before {
	content: '\e893'
}

.icon-angle-double-left:before {
	content: '\e894'
}

.icon-angle-double-right:before {
	content: '\e895'
}

.icon-angle-double-up:before {
	content: '\e896'
}

.icon-angle-double-down:before {
	content: '\e897'
}

.icon-down:before {
	content: '\e898'
}

.icon-left:before {
	content: '\e899'
}

.icon-right:before {
	content: '\e89a'
}

.icon-up:before {
	content: '\e89b'
}

.icon-down-big:before {
	content: '\e89c'
}

.icon-left-big:before {
	content: '\e89d'
}

.icon-right-big:before {
	content: '\e89e'
}

.icon-up-big:before {
	content: '\e89f'
}

.icon-right-hand:before {
	content: '\e8a0'
}

.icon-left-hand:before {
	content: '\e8a1'
}

.icon-up-hand:before {
	content: '\e8a2'
}

.icon-down-hand:before {
	content: '\e8a3'
}

.icon-left-circled:before {
	content: '\e8a4'
}

.icon-right-circled:before {
	content: '\e8a5'
}

.icon-up-circled:before {
	content: '\e8a6'
}

.icon-down-circled:before {
	content: '\e8a7'
}

.icon-cw:before {
	content: '\e8a8'
}

.icon-ccw:before {
	content: '\e8a9'
}

.icon-arrows-cw:before {
	content: '\e8aa'
}

.icon-level-up:before {
	content: '\e8ab'
}

.icon-level-down:before {
	content: '\e8ac'
}

.icon-check-empty:before {
	content: '\e8ad'
}

.icon-check:before {
	content: '\e8ae'
}

.icon-book:before {
	content: '\e8af'
}

.icon-circle:before {
	content: '\e8b0'
}

.icon-circle-empty:before {
	content: '\e8b1'
}

.icon-circle-thin:before {
	content: '\e8b2'
}

.icon-gift:before {
	content: '\e8b3'
}

.icon-user-md:before {
	content: '\e8b4'
}

.icon-stethoscope:before {
	content: '\e8b5'
}

.icon-twitter:before {
	content: '\e8b6'
}

.icon-tumblr:before {
	content: '\e8b7'
}

.icon-skype:before {
	content: '\e8b8'
}

.icon-gplus:before {
	content: '\e8b9'
}

.icon-signal:before {
	content: '\e8ba'
}

.icon-down-1:before {
	content: '\e8bb'
}

.icon-left-1:before {
	content: '\e8bc'
}

.icon-right-1:before {
	content: '\e8bd'
}

.icon-up-1:before {
	content: '\e8be'
}

.icon-vcard:before {
	content: '\e8bf'
}

.icon-alert:before {
	content: '\e8c0'
}

.icon-attention-1:before {
	content: '\e8c1'
}

.icon-search-1:before {
	content: '\e8c2'
}

.icon-user-1:before {
	content: '\e8c3'
}

.icon-users-1:before {
	content: '\e8c4'
}

.icon-user-add:before {
	content: '\e8c5'
}

.icon-cancel-1:before {
	content: '\e8c6'
}

.icon-check-1:before {
	content: '\e8c7'
}

.icon-home-1:before {
	content: '\e8c8'
}

.icon-attach-1:before {
	content: '\e8c9'
}

.icon-users-2:before {
	content: '\e8ca'
}

.icon-location-1:before {
	content: '\e8cb'
}

.icon-location-2:before {
	content: '\e8cc'
}

.icon-calendar-1:before {
	content: '\e8cd'
}

.icon-user-2:before {
	content: '\e8ce'
}

.icon-group:before {
	content: '\e8cf'
}

.icon-search-2:before {
	content: '\e8d0'
}

.icon-user-3:before {
	content: '\e8d1'
}

.icon-ok-1:before {
	content: '\e8d2'
}

.icon-ok-circle:before {
	content: '\e8d3'
}

.icon-flight:before {
	content: '\e8d4'
}

.icon-path:before {
	content: '\e8d5'
}

.icon-accessibility:before {
	content: '\e8d6'
}

.icon-gift-1:before {
	content: '\e8d7'
}

.icon-check-2:before {
	content: '\e8d8'
}

.icon-certificate:before {
	content: '\e8d9'
}

.icon-down-2:before {
	content: '\e8da'
}

.icon-left-2:before {
	content: '\e8db'
}

.icon-right-2:before {
	content: '\e8dc'
}

.icon-up-2:before {
	content: '\e8dd'
}

.icon-phone-1:before {
	content: '\e8de'
}

.icon-location-3:before {
	content: '\e8df'
}

.icon-download-alt:before {
	content: '\e8e0'
}

.icon-pencil-1:before {
	content: '\e8e1'
}

.icon-edit-1:before {
	content: '\e8e2'
}

.icon-edit-alt:before {
	content: '\e8e3'
}

.icon-tag-1:before {
	content: '\e8e4'
}

.icon-tags-1:before {
	content: '\e8e5'
}

.icon-picture-1:before {
	content: '\e8e6'
}

.icon-ok-2:before {
	content: '\e8e7'
}

.icon-help-1:before {
	content: '\e8e8'
}

.icon-heart-1:before {
	content: '\e8e9'
}

.icon-music-1:before {
	content: '\e8ea'
}

.icon-facebook:before {
	content: '\e8eb'
}

.icon-twitter-1:before {
	content: '\e8ec'
}

.icon-skype-1:before {
	content: '\e8ed'
}

.icon-google:before {
	content: '\e8ee'
}

.icon-tumblr-1:before {
	content: '\e8ef'
}

.icon-cart:before {
	content: '\e8f0'
}

.icon-gplus-1:before {
	content: '\e8f1'
}

.icon-pinterest:before {
	content: '\e8f2'
}

.icon-tree-1:before {
	content: '\e8f3'
}

.icon-tree-2:before {
	content: '\e8f4'
}

.icon-police:before {
	content: '\e8f5'
}

.icon-school:before {
	content: '\e8f6'
}

.icon-shop:before {
	content: '\e8f7'
}

.icon-fuel:before {
	content: '\e8f8'
}

.icon-bicycle:before {
	content: '\e8f9'
}

.icon-bus:before {
	content: '\e8fa'
}

.icon-cafe:before {
	content: '\e8fb'
}

.icon-commerical-building:before {
	content: '\e8fc'
}

.icon-gplus-2:before {
	content: '\e8fd'
}

.icon-left-fat:before {
	content: '\e8fe'
}

.icon-right-fat:before {
	content: '\e8ff'
}

.icon-ccw-1:before {
	content: '\e900'
}

.icon-up-fat:before {
	content: '\e901'
}

.icon-down-fat:before {
	content: '\e902'
}

.icon-right-bold:before {
	content: '\e903'
}

.icon-up-bold:before {
	content: '\e904'
}

.icon-down-bold:before {
	content: '\e905'
}

.icon-left-bold:before {
	content: '\e906'
}

.icon-down-open-1:before {
	content: '\e907'
}

.icon-left-open-1:before {
	content: '\e908'
}

.icon-right-open-1:before {
	content: '\e909'
}

.icon-up-open-1:before {
	content: '\e90a'
}

.icon-down-3:before {
	content: '\e90b'
}

.icon-left-3:before {
	content: '\e90c'
}

.icon-right-3:before {
	content: '\e90d'
}

.icon-up-3:before {
	content: '\e90e'
}

.icon-zoom-in:before {
	content: '\e90f'
}

.icon-zoom-out:before {
	content: '\e910'
}

.icon-stopwatch:before {
	content: '\e911'
}

.icon-clock-1:before {
	content: '\e912'
}

.icon-chat-1:before {
	content: '\e913'
}

.icon-cancel-2:before {
	content: '\e914'
}

.icon-user-female:before {
	content: '\e915'
}

.icon-ok-3:before {
	content: '\e916'
}

.icon-user-4:before {
	content: '\e917'
}

.icon-users-3:before {
	content: '\e918'
}

.icon-down-open-2:before {
	content: '\e919'
}

.icon-left-open-2:before {
	content: '\e91a'
}

.icon-right-open-2:before {
	content: '\e91b'
}

.icon-up-open-2:before {
	content: '\e91c'
}

.icon-down-open-mini:before {
	content: '\e91d'
}

.icon-left-open-mini:before {
	content: '\e91e'
}

.icon-right-open-mini:before {
	content: '\e91f'
}

.icon-up-open-mini:before {
	content: '\e920'
}

.icon-down-open-big:before {
	content: '\e921'
}

.icon-left-open-big:before {
	content: '\e922'
}

.icon-right-open-big:before {
	content: '\e923'
}

.icon-up-open-big:before {
	content: '\e924'
}

.icon-down-dir-1:before {
	content: '\e925'
}

.icon-left-dir-1:before {
	content: '\e926'
}

.icon-right-dir-1:before {
	content: '\e927'
}

.icon-up-dir-1:before {
	content: '\e928'
}

.icon-down-bold-1:before {
	content: '\e929'
}

.icon-left-bold-1:before {
	content: '\e92a'
}

.icon-right-bold-1:before {
	content: '\e92b'
}

.icon-up-bold-1:before {
	content: '\e92c'
}

.icon-minus-1:before {
	content: '\e92d'
}

.icon-plus-1:before {
	content: '\e92e'
}

.icon-help-2:before {
	content: '\e92f'
}

.icon-youtube-play:before {
	content: '\e930'
}

.icon-smile:before {
	content: '\e931'
}

.icon-frown:before {
	content: '\e932'
}

.icon-meh:before {
	content: '\e933'
}

.icon-newspaper:before {
	content: '\e934'
}

.icon-certificate-1:before {
	content: '\e935'
}

.icon-wifi:before {
	content: '\e936'
}

.icon-left-4:before {
	content: '\e937'
}

.icon-right-4:before {
	content: '\e938'
}

.icon-list:before {
	content: '\e939'
}

.icon-calendar-2:before {
	content: '\e93a'
}

.icon-mobile:before {
	content: '\e93b'
}

.icon-chart-pie:before {
	content: '\e93c'
}

.icon-megaphone:before {
	content: '\e93d'
}

.icon-th-1:before {
	content: '\e93e'
}

.icon-left-open-3:before {
	content: '\e93f'
}

.icon-right-open-3:before {
	content: '\e940'
}

.icon-lock-1:before {
	content: '\e941'
}

.icon-left-open-4:before {
	content: '\e942'
}

.icon-right-open-4:before {
	content: '\e943'
}

.icon-down-4:before {
	content: '\e944'
}

.icon-left-5:before {
	content: '\e945'
}

.icon-right-5:before {
	content: '\e946'
}

.icon-up-4:before {
	content: '\e947'
}

.icon-down-small:before {
	content: '\e948'
}

.icon-left-small:before {
	content: '\e949'
}

.icon-right-small:before {
	content: '\e94a'
}

.icon-up-small:before {
	content: '\e94b'
}

.icon-star-filled:before {
	content: '\e94c'
}

.icon-back-in-time:before {
	content: '\e94d'
}

.icon-gplus-3:before {
	content: '\e94e'
}

.icon-list-bullet:before {
	content: '\e94f'
}

.icon-scissors:before {
	content: '\e950'
}

.icon-paste:before {
	content: '\e951'
}

.icon-off:before {
	content: '\e952'
}
