.modal,
.modal-box {
  z-index: 900;
}

.modal-sandbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
}

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, .8);
  overflow: auto;

  &-box {
    position: relative;
    width: 50%;
    max-width: 920px;
    margin: 100px auto;
    animation-name: modalbox;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0, 0, .3, 1.6);

    @media #{$r414} {
      margin: 20px auto 0;
      width:90%;
    }
  }

  &-header {
    padding: 10px 15px 15px 25px;
    background: $color1;
    color: #fff;
    border-radius: 4px 4px 0 0;

    h3 {
      margin: 0;
    }

    @media #{$r414} {
      padding: 10px 20px;
    }
  }

  &-body {
    background: #ECEFF1;
    padding: 25px 30px;
    line-height: 26px;
    border-radius: 0 0 4px 4px;

    @media #{$r414} {
      padding: 20px;
    }
  }
}




.close-modal {
  text-align: right;
  cursor: pointer;
  color:#fff;
}

/* Animation */
@-webkit-keyframes modalbox {
  0% {
    top: -250px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes modalbox {
  0% {
    top: -250px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}